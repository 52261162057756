@font-face {
    font-family: 'marlboro';
    src: url('./marlborofont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  .triangle-container {
    position: relative;
    height: 50px;
    width: 100%;
  }
  
  .triangle-left,
  .triangle-right,
  .triangle-left-mobile,
  .triangle-right-mobile
  {
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  .triangle-left {
    border-width: 12vw 50vw 0 0;
    border-color: #C80000 transparent transparent transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-100%);
    z-index: -10;
  }
  
  .triangle-right {
    border-width: 12vw 0 0 50vw;
    border-color: #C80000 transparent transparent transparent;
    position: absolute;
    right: 50%;
    transform: translateX(100%);
    z-index: -10;
  }
  
  .triangle-left-mobile {
    border-width: 28vw 50vw 0 0;
    border-color: #C80000 transparent transparent transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-100%);
    z-index: -10;
  }
  
  .triangle-right-mobile {
    border-width: 28vw 0 0 50vw;
    border-color: #C80000 transparent transparent transparent;
    position: absolute;
    right: 50%;
    transform: translateX(100%);
    z-index: -10;
  }